import React from "react";
import { Helmet } from "react-helmet";
import '../styles/styles.css';

import paalaeg from '../images/index2.jpg'
import paalaeg2 from '../images/paalaeg.jpg'
import spegepoelse from '../images/index3.jpg'

//Components
import Slider from '../components/Slider'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Paalaeg = () => {
    const images = [paalaeg, paalaeg2, spegepoelse]

    return (
        <>
            <Helmet>
                <title>Pålæg | Klippingeslagteren</title>
            </Helmet>
            <Navbar />
            <div className='mt-4 w-11/12 mt-28 mx-auto lg:mt-40 pb-6'>
                <div className='flex flex-col justify-center items-center p-3 '>
                    <div className='w-full flex flex-col p-6 px-6 mb-4 lg:w-3/4'>
                        <h2 className='font-bold text-center text-2xl'>Vi laver alt pålæg i huset af de bedste danske råvarer.</h2>
                        <br/>
                        <p>
                            Vores pålægs- og pølsemagerivarer er det, som mange kommer langvejs fra for at købe.
                            Kvalitet og godt håndværk er fællesnævnerne for vores varer, som alle fremstilles i vores eget pølsemageri
                            og i egen røgeovn.
                            Vi har rigtig mange års erfaring og er derfor gode til at tilpasse behovet for varer i butikken og fremstille varer derefter.
                            Sortimentet af pålægsvarer er stort.
                            Vi tilbyder bl.a. <br />
                            Pålægspølser: Rullepølse, kødpølse, røget medister og bierwurst. <br />
                            Spegepølser: Hvidløg, øl/kartoffel, okse, salami m.fl. <br />
                            Salater: Rejesalat, kvidr, italiensksalat m.fl. <br />
                            Derudover tilbyder vi flere slags leverpostej (bagselv).<br />
                            Om sommeren producerer vi pølser til grillen af flere forskellige slags, fx ostepølser og chorizopølser
                            som supplement til frankfurteren, som sælges hele året. <br />
                            I efterårs- og vinterperioden vil hjemmelavet sylte være at finde i butikken.<br /><br />

                            Der er massere at vælge i mellem og noget for enhver smag,
                            så kom endelig forbi i vores åbningstid, hvor vi vil stå klar til at assistere dig.
                        </p>
                    </div>
                    <div className='w-full mb-4 lg:w-3/4'>
                        <Slider images={images} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Paalaeg